<script>
import AuthTemplate from '../AuthTemplate.vue'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import InputField from '@/components/general/InputField'
import { mapActions } from 'vuex'
export default {
  name: 'NewPasswordPage',
  components: {
    AuthTemplate,
    InputField
  },
  data: () => ({
    theme: '',
    responseStatus: '',
    isAttemptingChangePassword: false,
    formData: {
      password: null,
      repeatPassword: null
    },
    availableThemes: [
      { themeName: 'empregosagro', color: 'empregosagro', logo: '/assets/images/customers/CNA.svg', secondary: '#62B55A' },
      { themeName: 'empregatransporte', color: 'empregatransporte', logo: '/assets/images/customers/sestsenat.svg', secondary: '#2e72e0' },
      { themeName: 'vivae', color: 'vivae', logo: '/assets/images/customers/viva-e.png', secondary: '#72009C' },
      { themeName: 'portalfenacon', color: 'portalfenacon', logo: '/assets/images/customers/portalfenacon.png', secondary: '#4196B7' },
      { themeName: 'pwfglobal', color: 'pwfglobal', logo: '/assets/images/customers/wfglobal-logo.png', secondary: '#AA2721' },
      { themeName: 'acats', color: 'acats', logo: '/assets/images/customers/acats.png', secondary: '#2D5AA4' }
    ]
  }),
  validations: {
    formData: {
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(20),
        passwordIsValid: function () {
          return this.passwordRulesValidation(this.formData.password, 'valid')
        },
        passwordContainSpecialCharacter: function () {
          return this.passwordRulesValidation(this.formData.password, 'special')
        },
        passwordContainUpperCharacter: function () {
          return this.passwordRulesValidation(this.formData.password, 'upper')
        },
        passwordContainLowerCharacter: function () {
          return this.passwordRulesValidation(this.formData.password, 'lower')
        },
        passwordContainNumber: function () {
          return this.passwordRulesValidation(this.formData.password, 'number')
        }
      },
      repeatPassword: {
        required,
        maxLength: maxLength(20),
        passwordConfirmation: function () {
          return this.formData.password === this.formData.repeatPassword
        }
      }
    }
  },
  methods: {
    ...mapActions(['setLanguage']),
    changeLanguage (language) {
      this.$emit('language', language)
      this.setLanguage(language)
    },
    sendNewPassword () {
      this.isAttemptingChangePassword = true
      const params = {
        body: {
          password: this.formData.password,
          confirm: this.formData.repeatPassword,
          template: 'customer:password_changed-' + this.$route.params.language,
          subject: this.$t('new.password.form:sucess')
        },
        code: this.$route.params.code
      }

      this.$store.dispatch('attemptNewPassword', params).then(response => {
        this.responseStatus = response.status
        this.$store.dispatch('attemptSetFeedbackMsg', {
          type: 'success',
          title: this.$t('settings.account.labels:submit.change.password.done')
        })
        this.$router.push({ name: 'auth.login', query: { theme: this.$route.query.theme } }).catch(() => { })
      }).catch(err => {
        this.responseStatus = err.response.status
        this.$store.dispatch('attemptSetFeedbackMsg', {
          type: 'error',
          title: this.$t('new.password.form:error')
        })
      }).finally(
        this.isAttemptingChangePassword = false
      )
    }
  },
  created () {
    this.changeLanguage(this.$route.params.language)
    this.appliedTheme = Object.prototype.hasOwnProperty.call(this.$route.query, 'theme') && this.availableThemes.filter(theme => theme.themeName === this.$route.query.theme)[0]
    if (this.$route.query.theme) this.theme = '?theme=' + this.$route.query.theme
    // eslint-disable-next-line no-undef
    zE('webWidget', 'hide')
  },
  beforeRouteLeave (to, from, next) {
    // eslint-disable-next-line no-undef
    zE('webWidget', 'show')
    next()
  }
}
</script>
<template>
  <auth-template :color="appliedTheme.color ? appliedTheme.color : 'defaultLogin'" :logo="appliedTheme.logo"
    :language="this.$route.params.language" :title="$t('global:welcome')" :subtitle="$t('login.page:subtitle')">
    <template v-slot:image>
      <img
        :src="appliedTheme.color ? `/assets/images/customers/bg-${appliedTheme.color}.png` : '/assets/images/customers/plooral-hand.png'"
        height="100%" />
    </template>
    <template v-slot:form>
      <div class="recovery--container">
        <v-card class="recovery-form--wrapper">
          <v-btn text class="recovery-form--button lato bold" :color="appliedTheme.secondary" :to="`/recovery${theme}`">
            <v-icon class="recovery-form--button-chevron">mdi-chevron-left</v-icon>
            {{ $t('global:back') }}
          </v-btn>
          <h4>{{ $t('new.password.form:title') }}</h4>
          <p class="recovery-form-subtitle" v-html="$t('new.password.form:subtitle', { registrationUrl: `/login${theme}` })" />
          <div v-show="responseStatus === 500" class="response-area-error">{{ $t('recovery.form.error') }}</div>
          <div v-show="responseStatus === 204" class="response-area-success">
            {{ $t('recovery.form.success') }}</div>
          <input-field :disabled="isAttemptingChangePassword" type="password" outlined v-model="formData.password"
            :label="$t('settings.account.labels:change.password.actual.new.pass')"
            :validation="$v.formData.password" :hide-validation="!$v.formData.password.$error && isMobile" />
          <input-field :disabled="isAttemptingChangePassword" type="password" outlined v-model="formData.repeatPassword"
            :label="$t('settings.account.labels:change.password.actual.confirm.new.pass')"
            :validation="$v.formData.repeatPassword"
            :hide-validation="!$v.formData.repeatPassword.$error && isMobile" />

          <v-btn class="mt-10 btn bold transform-unset" style="color: white; font-size: 16px;"
            :color="appliedTheme.secondary ? appliedTheme.secondary : '#1200D3'" @click="sendNewPassword"
            :loading="isAttemptingChangePassword" :disabled="$v.$invalid || isAttemptingChangePassword" large block>{{
  $t('global:send')
            }}</v-btn>
        </v-card>
      </div>
    </template>
  </auth-template>
</template>
<style lang="scss">
.response-area-error {
  color: #a94442;
  background-color: #f2dede;
  border: none;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  padding: 15px;
  border-radius: 4px;
}

.response-area-success {
  color: #3c763d;
  background-color: #dff0d8;
  border: none;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  padding: 15px;
  border-radius: 4px;
}

.recovery--container {
  max-width: 100%;
  width: 515px;

  .recovery-form--wrapper {
    padding: 45px 64px;
    border-radius: 10px;
    text-align: left;

    .v-input .v-label {
      color: rgba(0,0,0,.6) !important;
    }
    .recovery-form-subtitle {
      @extend .subtitle--1;
      @extend .neutral-medium;
    }

    .recovery-form--button {
      color: $primary-medium;
      padding-left: 0;

      .recovery-form--button-chevron {
        margin-right: 10px;
        margin-top: 2px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .recovery-form--wrapper {
      border-radius: 10px 10px 0 0;
      padding: 36px 24px 80px;
    }
  }
}
</style>
